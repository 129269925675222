import React from "react"
import Layout from "../../components/layout"
import {Jumbotron, Button} from 'react-bootstrap'

const quotes = [
    {
      quote:
        "Life is too short and sweet to be spent by cribbing and complaining about things. Here are some random quotes about the most wonderful gift that we've got",
      author: " Life"
    },
    {
      quote:
        "Humor is richly rewarding to the person who employs it. It has some value in gaining and holding attention. But it has no persuasive value at all",
      author: "John Kenneth Galbraith"
    },
    {
      quote:
        "God save me from my friends. I can protect myself from my enemies.",
      author: "Claude Louis Hector de Villars "
    },
    {
      quote: "The price of anything is the amount of life you exchange for it.",
      author: "David Thoreau"
    },
    {
      quote:
        "Life is like a landscape. You live in the midst of it but can describe it only from the vantage point of distance. ",
      author: "Charles Lindbergh"
    },
    {
      quote:
        "A critic is someone who never actually goes to the battle, yet who afterwards comes out shooting the wounded.",
      author: " Tyne Daly"
    }
];



const QuotesGenerator = () => {
    // const btn = document.getElementById("generate-btn");
    let random = Math.floor(Math.random() * quotes.length);
    document.getElementById("quotes").textContent = quotes[random].quote;
    document.getElementById("author").textContent = quotes[random].author;
}

export default () => (
  <Layout title="Contest | Random Quotes">
    <Jumbotron className="bg-lightblue">
        <div className="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
            <div className="randomQuoteOuter">
             <Button className="btn-primary" id="quotes-button" onClick={() => QuotesGenerator()}>Generate Quote</Button>
             <p id="quotes">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur sint iste optio placeat dolor labore, reprehenderit ipsum aspernatur blanditiis maiores.</p>
             <h4>- <span id="author">Author</span></h4> 
           </div>
        </div>
        </div>
    </Jumbotron>
  </Layout>
)